import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useInvoiceList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refInvoicesTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'Trx_No',
      label: 'Invoice & File No.',
      sortable: false,
    },
    {
      key: 'fileType',
      label: 'Product Description',
      sortable: false,
    },
    {
      key: 'Entity_Name',
      label: 'customer',
      sortable: false,
    },
    {
      key: 'Trx_Status',
      label: 'invoice Status',
      sortable: false,
    },
    {
      key: 'Created_On',
      label: 'Created By',
      sortable: true,
    },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalInvoices = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const fileNo = ref(route.query.fileNo ?? '')
  const invoiceNo = ref(route.query.invoiceNo ?? '')
  const nric = ref(route.query.nric ?? '')
  const lotNo = ref(route.query.lotNo ?? '')
  const sortBy = ref(route.query.sortBy ?? 'File_No')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const fileTypeFilter = ref([])
  if (Array.isArray(route.query.fileType)) {
    fileTypeFilter.value = route.query.fileType
  } else if (route.query.fileType) {
    fileTypeFilter.value = [route.query.fileType]
  }
  const invoiceStatusFilter = ref([])
  if (Array.isArray(route.query.invoiceStatus)) {
    invoiceStatusFilter.value = route.query.invoiceStatus
  } else if (route.query.invoiceStatus) {
    invoiceStatusFilter.value = [route.query.invoiceStatus]
  }
  const invoicesData = ref([])
  const invoiceDateFilter = ref(route.query.invoiceDate || '')
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refInvoicesTable.value ? refInvoicesTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoicesTable.value.refresh()
  }

  const fetchInvoices = () => {
    store
      .dispatch('app-all-invoices/fetchInvoices', {
        search: searchQuery.value,
        fileNo: fileNo.value,
        invoiceNo: invoiceNo.value,
        nric: nric.value,
        lotNo: lotNo.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        invoiceStatus: invoiceStatusFilter.value,
        fileType: fileTypeFilter.value,
        invoiceDate: invoiceDateFilter.value,
      },
      { root: true })
      .then(response => {
        const { invoices, totalInvoices: totalInvoicesCount } = response.data
        invoicesData.value = invoices
        // callback(invoices)
        totalInvoices.value = totalInvoicesCount
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              fileNo: fileNo.value,
              invoiceNo: invoiceNo.value,
              nric: nric.value,
              lotNo: lotNo.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              invoiceStatus: invoiceStatusFilter.value,
              fileType: fileTypeFilter.value,
              invoiceDate: invoiceDateFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  let timer = null
  watch([fileTypeFilter, invoiceStatusFilter, invoiceDateFilter], () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      currentPage.value = 1
      refetchData()
    }, 1000)
  }, { deep: true })

  watch([searchQuery], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchInvoices()
      }
    }, 1000)
  }, { deep: true })

  watch([fileNo], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchInvoices()
      }
    }, 1000)
  }, { deep: true })

  watch([nric], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchInvoices()
      }
    }, 1000)
  }, { deep: true })

  watch([invoiceNo], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchInvoices()
      }
    }, 1000)
  }, { deep: true })

  watch([lotNo], (current, prev) => {
    const currentVal = current[0]
    const prevVal = prev[0]
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if ((!currentVal && prevVal) || (currentVal && currentVal.length > 2)) {
        currentPage.value = 1
        fetchInvoices()
      }
    }, 1000)
  }, { deep: true })

  watch([currentPage], () => {
    fetchInvoices()
  }, { deep: true })

  fetchInvoices()

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    fileNo,
    invoiceNo,
    nric,
    lotNo,
    sortBy,
    isSortDirDesc,
    refInvoicesTable,
    refetchData,
    invoicesData,

    // Extra Filters
    fileTypeFilter,
    invoiceStatusFilter,
    invoiceDateFilter,
  }
}
